import React from 'react'
import CountUp from 'react-countup';
import aboutScreen from "../../assets/images/about-app.png"
import featuresIcon from "../../assets/images/features.png"
import followersIcon from "../../assets/images/followers.png"
import reviewsIcon from "../../assets/images/reviews.png"
import countriesIcon from "../../assets/images/countries.png"
import appstoreBlue from "../../assets/images/appstore_black.png"
import googleplayBlue from "../../assets/images/google_badge_color.png"
import qrCode from "../../assets/images/QR-code.png"
import awardImg from "../../assets/images/award.png"
import "../../styles/css/about-app.css"

export const AboutAppSection = () => {
    return (
        <div>
            <section className="row_am about_app_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about_img" data-aos="fade-in" data-aos-duration="1500">
                                <div className="frame_img">
                                    <img className="moving_position_animatin" src={aboutScreen} alt="about_screen" />
                                </div>
                                <div className="screen_img">
                                    <img className="moving_animation" src={awardImg} alt="award_image" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about_text">
                                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                    <h1>Some awesome words <span>about <br style={{display: 'none'}}/>4E HUB app.</span></h1>
                                    <p>
                                        The 4E HUB app, an all-in-one application, is made to maximize users' access to information and functions. The end result is the culmination of a protracted process that involved looking for information, choosing sources, and gathering data from diversity platforms.
                                    </p>
                                </div>
                                <ul className="app_statstic" id="counter" data-aos="fade-in" data-aos-duration="1500">
                                    <li>
                                        <div className="icon">
                                            <img src={featuresIcon} alt="features_icon" />
                                        </div>
                                        <div className="text">
                                            <p><CountUp start={0} end={10} duration={2} enableScrollSpy>
                                                {({ countUpRef }) => (
                                                    <>
                                                        <span ref={countUpRef} />+
                                                    </>
                                                )}</CountUp>
                                                </p>
                                            <p>Features</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <img src={followersIcon} alt="followers_icon" />
                                        </div>
                                        <div className="text">
                                            <p><CountUp start={0} end={500} duration={2} enableScrollSpy >
                                                {({ countUpRef }) => (
                                                    <>
                                                        <span ref={countUpRef} />+
                                                    </>
                                                )}</CountUp>
                                                
                                            </p>
                                            <p>Posts</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <img src={reviewsIcon} alt="reviews_icon" />
                                        </div>
                                        <div className="text">
                                            <p><CountUp start={0} end={2300} duration={1} enableScrollSpy>
                                                {({ countUpRef }) => (
                                                    <>
                                                        <span ref={countUpRef} />+
                                                    </>
                                                )}</CountUp></p>
                                            <p>Reviews</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <img src={countriesIcon} alt="countries_icon" />
                                        </div>
                                        <div className="text">
                                            <p><CountUp start={0} end={150} duration={2} enableScrollSpy>{({ countUpRef }) => (
                                                <>
                                                    <span ref={countUpRef} />+
                                                </>
                                            )}</CountUp></p>
                                            <p>Countries</p>
                                        </div>
                                    </li>
                                </ul>
                                <div className='download-app-greeting'>
                                    <div className='download-app'>
                                        <p className='label-download' style={{ display: 'none' }}>Download our app!</p>
                                        <ul className="app_btn">
                                            <div className='row-left'>
                                                <img src={qrCode} alt="qr-code" className='qr-img' />
                                            </div>
                                            <li>
                                                <a href="https://play.google.com/store/apps/details?id=com.hdempires.hd4ehub" target='_blank'>
                                                    <img src={googleplayBlue} alt="google_play_badge"/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://apps.apple.com/app/4e-hub/id6448568895" target='_blank'>
                                                    <img src={appstoreBlue} className="appstore-badge" alt="app_store_badge"/>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}