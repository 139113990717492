import React, {useEffect, useState} from 'react'
import { Link, useLocation } from 'react-router-dom'
import "../../styles/css/bred-crumb.css"

export const BredCrumbSection = ({props}) => {
  const currentPath = useLocation();
  const [detailsPage, setDetailsPage] = useState({
    title: '',
    namePage : '',
    description: '',
  })

  const changeNamePage = () => {
    switch (currentPath.pathname) {
      case "/privacy-policy":
        setDetailsPage({
          title : "Privacy policy",
          namePage : "Privacy policy",
          description: " ",
        })
        break;
        case "/terms-conditions":
        setDetailsPage({
          title : "Terms conditions",
          namePage : "Terms conditions",
          description: "",
        })
        break;
      default:
        setDetailsPage({
          title : "404",
          namePage : "404",
          description: " ",
        })
        break
    }
  }
  useEffect(() => {
    changeNamePage()

  }, [currentPath.pathname])

  return (
    <div className="bred_crumb" {...props}>
      <div className="container">
        <div className="bred_text">
          <h1>{ detailsPage.title}</h1>
          <p className={`${detailsPage.description === '' ? "none" : "block"}`}>{detailsPage.description}</p>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><span>»</span></li>
            <li><Link to={currentPath.pathname}>{detailsPage.namePage}</Link></li>
          </ul>
        </div>
      </div>
    </div>
  )
}
