import React from 'react'
import kol from "../../assets/images/kol-user.png"
import normalUsers from "../../assets/images/normal-user.png"
import projectOwner from "../../assets/images/project-owner.png"
import "../../styles/css/type-user.css"

export const TypeUsersSection = () => {
    return (
        <section className="row_am type-users_section" id="type-users">
            <div className="container" >
                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                    <h1>Who should use <span>4E HUB App?</span></h1>

                    <p>4E HUB is designed to cater for 3 different groups of customers.</p>
                </div>
                <div className="type-users_pannel active" data-aos="fade-up" data-aos-duration="1500">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="type-users_block">
                                <div className="icon">
                                    <img src={kol} alt="kol-user" />
                                </div>
                                <div className="pkg_name">
                                    <h3>KOL Users</h3>
                                    <span>KOLs in the Cryptocurrency Market</span>
                                </div>
                                <ul className="benifits type-1">
                                    <li>
                                        <p>Become a part of the worldwide KOL community</p>
                                    </li>
                                    <li>
                                        <p>Work with prestigious projects</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="type-users_block highlited_block">
                                <div className="icon">
                                    <img src={normalUsers} alt="normal-user" />
                                </div>
                                <div className="pkg_name">
                                    <h3>Normal Users</h3>
                                <span>Clients in need of investments</span>
                                </div>
                                <ul className="benifits type-2">
                                    <li>
                                        <p>Use limitless features</p>
                                    </li>
                                    <li>
                                        <p>Amplify investment opportunities</p>
                                    </li>
                                    <li>
                                        <p>Update information and knowledge that has been carefully selected for quality</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="type-users_block">
                                <div className="icon">
                                    <img src={projectOwner} alt="project-owner" />
                                </div>
                                <div className="pkg_name">
                                    <h3>Project Owner</h3>
                                    <span>Newly established projects and projects <br/> seeking to expand</span>
                                </div>
                                <ul className="benifits type-3">
                                    <li>
                                        <p>Partner with famous KOLs </p>
                                    </li>
                                    <li>
                                        <p>Collaborate with other potential project files</p>
                                    </li>
                                    <li>
                                        <p>Recruit personal</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}