import React from 'react'
import iconEveryone from "../../assets/images/icon-everyone.png"
import iconEverytime from "../../assets/images/icon-everytime.png"
import iconEverywhere from "../../assets/images/icon-everywhere.png"
import iconEverything from "../../assets/images/icon-everything.png"
import homeScreenImg from "../../assets/images/intro_screen.png"
import mobileFrame from "../../assets/images/mobile_frame_svg.svg"
import "../../styles/css/features.css"

export const FeaturesSection = () => {

    return (
        <section className="row_am features_section" id="features">
            <div className="container">
                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                    <h1><span>Features</span> that make 4E HUB App different!</h1>
                </div>
                <div className="feature_detail">
                    <div className="left_data feature_box">
                        <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                            <div className="icon">
                                <img src={iconEveryone} alt="icon_everyone" style={{width: '120px'}}/>
                            </div>
                            <div className="text">
                                <h4>Everyone</h4>
                                <p>Provide a wide range of capabilities for both newbies and seasoned users in the crypto world.</p>
                            </div>
                        </div>
                        <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                            <div className="icon">
                                <img src={iconEverytime} alt="icon_everytime" />
                            </div>
                            <div className="text">
                                <h4>Everytime</h4>
                                <p>Allow for quick and easy tracking of data changes.</p>
                            </div>
                        </div>
                    </div>
                    <div className="right_data feature_box">
                        <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                            <div className="icon">
                                <img src={iconEverywhere} alt="icon_everywhere" />
                            </div>
                            <div className="text">
                                <h4>Everywhere</h4>
                                <p>Can be used wherever you are. Certain functions are also available offline.</p>
                            </div>
                        </div>
                        <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                            <div className="icon">
                                <img src={iconEverything} alt="icon_everything" />
                            </div>
                            <div className="text">
                                <h4>Everything</h4>
                                <p>Provide a comprehensive overview of the crypto market for users to catch up on the latest trends.</p>
                            </div>
                        </div>

                    </div>
                    <div className="feature_img feature_img_frame" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                        <img src={mobileFrame} alt="mobile_frame" />
                        <div className="feature_img feature_img_screen">
                            <img src={homeScreenImg} alt="home_screen" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}