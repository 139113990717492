import React from 'react'
import OwlCarousel from 'react-owl-carousel2';
import user1 from "../../assets/images/testimonial_user1.png"
import user2 from "../../assets/images/testimonial_user2.png"
import user3 from "../../assets/images/testimonial_user3.png"
import avatarBg from "../../assets/images/avtar_testimonial.png"
import "../../styles/css/testimonial.css"

export const TestimonialSection = () => {
    const options = {
        loop: true,
        margin: 10,
        nav: false,
        autoplay: true,
        smartSpeed: 2500,
        dots: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    }

    const testimonialList = [
        {
            id: 0,
            name: 'Shayna John',
            designation: 'Careative inc',
            comments: 'This 4E HUB App is really cool! I have made a considerable amount of money in crypto just by using the useful features of 4E HUB.',
            star: 5,
            avatar: user1,
        },
        {
            id: 1,
            name: 'Willium Den',
            designation: 'Careative inc',
            comments: 'I love this app because it gives me the latest and most valuable news in real time. The articles are posted very professionally, helping me update information quickly and effectively.',
            star: 5,
            avatar: user2,
        },
        {
            id: 2,
            name: 'Cyrus Stephen',
            designation: 'Careative inc',
            comments: 'I received the airdrop from participating in the retroactive, testnet programs that the team compiled and posted in this app. Thank 4E HUB',
            star: 5,
            avatar: user3,
        }
    ]

    const renderStarRating = (star) => {
        let starHTML = ''
        for (var i = 0; i < star; i++) {
            starHTML += '<li><span><i class="icofont-star"></i></span></li>'
        }
        return starHTML
    }

    return (
        <section className="row_am testimonial_section" id='testimonial'>
            <div className="container">
                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                    <h1>What our <span>customers say</span></h1>
                    <p>The majority of users of 4E HUB App are extremely satisfied. Check out what our clients are saying about us.</p>
                </div>
                <div className="testimonial_block" data-aos="fade-in" data-aos-duration="1500">
                    <OwlCarousel id="testimonial_slider" className="owl-carousel owl-theme" options={options}>
                        {
                            testimonialList.map(item => {
                                return <div className="item" key={item.id}>
                                    <div className="testimonial_slide_box">
                                        <ul className='rating' dangerouslySetInnerHTML={{ __html: renderStarRating(item.star) }}>
                                        </ul>
                                        <p className="review">
                                            “ {item.comments} ”
                                        </p>
                                        <div className="testimonial_img">
                                            <img src={item.avatar} alt="user-avatar" />
                                        </div>
                                        <h3>{item.name}</h3>
                                        <span className="designation">{item.designation}</span>
                                    </div>
                                </div>
                            })
                        }
                    </OwlCarousel>
                    <div className="total_review">
                        <div className="rating">
                            <span><i className="icofont-star"></i></span>
                            <span><i className="icofont-star"></i></span>
                            <span><i className="icofont-star"></i></span>
                            <span><i className="icofont-star"></i></span>
                            <span><i className="icofont-star"></i></span>
                            <p>5.0 / 5.0</p>
                        </div>
                    </div>
                    <div className="avtar_faces">
                        <img src={avatarBg} alt="avatar_background" />
                    </div>
                </div>
            </div>
        </section>
    )
}