import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import animLine from "../../assets/images/anim_line.png"
import whiteLogo from "../../assets/images/white_logo.png"
import DiscordIcon from "../../components/svg-assets/DiscordIcon"
import appstoreBlue from "../../assets/images/appstore_black.png"
import googleplayBlue from "../../assets/images/googleplay_black.png"
import goTop from "../../assets/images/go_top.png"
import "../../styles/css/footer.css"

export const Footer = () => {
    const [isShow, setIsShow] = useState(false);

    const scrollToTopHandler = () => {
        window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', () => {

        if (window.pageYOffset > 750) {
            setIsShow(true)

        } else {
            setIsShow(false)
        }
    })
    const [index, setIndex] = useState(0)
    const [section, setSection] = useState('')
    const scrollToSectionHandle = (section) => {
        setSection(section)
        setIndex(document.getElementById(section).offsetTop)

    }
    useEffect(() => {
        switch (section) {
            case 'faq':
                window.scrollTo(0, (index - 50))
                break;
            case 'how_it_work':
                window.scrollTo(0, (index - 40))
                break;
            default:
                window.scrollTo(0, 0)
                break;
        }

    }, [index])

    return (
        <footer>
            <div className="top_footer" id="contact">

                <div className="anim_line dark_bg">
                    <span><img src={animLine} alt="anim_line" /></span>
                    <span><img src={animLine} alt="anim_line" /></span>
                    <span><img src={animLine} alt="anim_line" /></span>
                    <span><img src={animLine} alt="anim_line" /></span>
                    <span><img src={animLine} alt="anim_line" /></span>
                    <span><img src={animLine} alt="anim_line" /></span>
                    <span><img src={animLine} alt="anim_line" /></span>
                    <span><img src={animLine} alt="anim_line" /></span>
                    <span><img src={animLine} alt="anim_line" /></span>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="abt_side">
                                <div className="logo"> <img src={whiteLogo} alt="logo_4E_HUB" /></div>
                                <ul>
                                    <li className='text-white email-contact'>Email: contact@4ehub.app</li>
                                </ul>
                                <ul className="social_media">
                                    <li><a href="https://discord.com/invite/V9vVdKybTr" target='_blank' rel="noreferrer"><DiscordIcon className="icon icon-discord" /></a></li>
                                    <li><a href="https://twitter.com/4ehub" target='_blank' rel="noreferrer"><i className="icofont-twitter"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 list-url">
                            <div className="links">
                                <h3>Useful links</h3>
                                <ul>
                                    <li><Link to="/" onClick={() => { window.scrollTo(0, 0) }}>Home</Link></li>
                                    <li><a href="https://play.google.com/store/apps/details?id=com.hdempires.hd4ehub" target='_blank' rel="noreferrer">Google Play</a></li>
                                    <li><a href="https://apps.apple.com/app/4e-hub/id6448568895" target='_blank' rel="noreferrer">App Store</a></li>
                                    <li><a href="https://linktr.ee/4ehub" target='_blank' rel="noreferrer">Contact us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 list-url">
                            <div className="links">
                                <h3>Help & Suport</h3>
                                <ul>
                                    <li><a onClick={() => scrollToSectionHandle('faq')}>FAQs</a></li>
                                    <li><a href="https://discord.gg/V9vVdKybTr" target='_blank' rel="noreferrer">Support</a></li>
                                    <li><a onClick={() => scrollToSectionHandle('how_it_work')}>How it works</a></li>
                                    <li><Link to="/terms-conditions" onClick={() => { window.scrollTo(0, 0) }}>Terms & conditions</Link></li>
                                    <li><Link to="/privacy-policy" onClick={() => { window.scrollTo(0, 0) }}>Privacy policy</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-12">
                            <div className="try_out">
                                <h3>Let’s Try Out</h3>
                                <ul className="app_btn_footer">
                                    <li>
                                        <a href="https://apps.apple.com/app/4e-hub/id6448568895" target='_blank' rel="noreferrer">
                                            <img src={appstoreBlue} alt="app_store_badge" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://play.google.com/store/apps/details?id=com.hdempires.hd4ehub" target='_blank' rel="noreferrer">
                                            <img src={googleplayBlue} alt="google_play_badge" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom_footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <p>© Copyrights 2023. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`go_top ${isShow ? 'show' : ''}`} onClick={scrollToTopHandler}>
                <span><img src={goTop} alt="go_top_button" /></span>
            </div>

        </footer>
    )
}