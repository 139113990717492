import React from 'react'
import newsScreen from "../../assets/images/news-frame.png"
import awardImg from "../../assets/images/award.png"
import staticImg from "../../assets/images/static.png"
import "../../styles/css/outstanding.css"

export const OutStanding = () => {
    return (
        <section className="row_am outstanding_section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="ui_text">
                            <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                <h1>Outstanding features of <span>4E HUB App</span></h1>
                                <p>
                                4E HUB App provides a unique experience by offering the most accurate data aggregation tools. With 4E HUB, users can access all the information they need without having to search for it in other apps.
                                </p>
                            </div>
                            <ul className="design_block">
                                <li data-aos="fade-up" data-aos-duration="1500">
                                    <h4>News</h4>
                                    <p>Synthesize the hot crypto market news instantly.</p>
                                </li>
                                <li data-aos="fade-up" data-aos-duration="1500">
                                    <h4>Economic Calendar</h4>
                                    <p>Update all significant economic indicators to help clients choose the best investments.</p>
                                </li>
                                <li data-aos="fade-up" data-aos-duration="1500">
                                    <h4>Tracking</h4>
                                    <p>Access indexes, well-known cryptocurrency values, or any token you like as provide the most comprehensive picture of the bitcoin sector.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="ui_images" data-aos="fade-in" data-aos-duration="1500">
                            <div className="left_img">
                                <img className="moving_position_animatin" src={newsScreen} alt="news_screen" />
                            </div>
                            <div className="right_img">
                                <img className="moving_position_animatin award_image" src={awardImg} alt="award_image" />
                                <img className="moving_position_animatin static_image" src={staticImg} alt="static_image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}