import React from 'react'
import { Navigation } from './Navigation'
import "../../styles/css/header.css"
import { HeaderTop } from './HeaderTop'

export const Header = () => {
  return (
    <div>
      <HeaderTop />
      <Navigation />
    </div>
  )
}