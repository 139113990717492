import React from 'react'
import bannerTerms from "../../assets/images/banner_term.png"
import "../../styles/css/terms-conditions.css"
import { BredCrumbSection } from '../bred-crumb/BredCrumbSection'

export const TermsConditions = () => {

  return (
    <>
      <BredCrumbSection />
      <section className="terms_conditions_section">
        <div className="container">
          <div className="terms_conditions_inner_pannel">
              <div className="main_img">
                <img src={bannerTerms} alt="terms_banner" />
              </div>
              <div className="info">
                <h3>Nature of the Website and Content Disclaimer</h3>
                <p>4EHUB mobile app - an all-in-one web3 solution that helps you secure your finances. With real-time news, economic calendars, expert insights, interactive charts, and more, etc. But none of the information you read on 4E Hub should be taken as investment advice in any way.4E Hub is not an investment adviser and you agree to not cite 4E Hub as the reason or cause for making any investments.</p>
                <p>You agree not to consider the news or market data on this site as a solicitation to invest in any cryptocurrencies, initial coin offerings, or other financial instruments.</p>
                <p>Buying and trading cryptocurrencies should be considered a high-risk activity. 4E Hub strongly encourages you to consult with a registered investment adviser or qualified investment professional and to do your own due diligence before making any investment decisions. 4E Hub is not accountable, directly or indirectly, for any damage or loss incurred, alleged or otherwise, in connection to the use or reliance of any content you read on the site.</p>          
              </div>
              <div className="info">
                <h3>Privacy</h3>
                <p>Please thoroughly review our Privacy Policy, which governs your use of our website.</p>
              </div>
              <div className='info'>
                <h3>Warranty Disclaimer and Limitation of Liability</h3>
                <p>4E Hub and its associates attempt to be as accurate as possible. However, 4E Hub does not warrant that the content of this site is accurate, complete, reliable, current, or error-free.</p>
                <p>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY: THIS SITE IS PROVIDED BY 4E HUB ON AN “AS IS” AND “AS AVAILABLE” BASIS. 4E HUB MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON THIS SITE. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, 4E HUB DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. 4E HUB DOES NOT WARRANT THAT THIS SITE, ITS SERVERS, OR E-MAIL SENT FROM 4E HUB ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. 4E HUB WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS SITE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES.</p>
              </div>
              <div className='info'>
                <h3>Copyright</h3>
                <p>All content included on this site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of  4E Hub or its content suppliers unless otherwise cited to its original copyright owners. The compilation of all content on this site is the exclusive property of 4E Hub, with copyright authorship for this collection by 4E Hub. You are authorized to view, play, print and download documents, audio and video found on our Websites for personal, informational, and non-commercial purposes only.</p>
              </div>
              <div className='info'>
                <h3>Access and Interference</h3>
                <p>You agree that you will not use any robot, spider, scraper, or other automated means to access the Websites for any purpose without our express written permission. Additionally, you agree that you will not: (1) take any action that imposes or may impose in our sole discretion an unreasonable or disproportionately large load on our or our vendors systems; (2) interfere or attempt to interfere with the proper working of the site or any activities conducted on the Websites; or (3) bypass any measures we may use to prevent or restrict access to the Websites.</p>
              </div>
              <div className='info'>
                <h3>Links to Other Sites</h3>
                <p>Our content may contain links to other sites. You will be directed to that site if you click on a third-party link.</p>
                <p>These links are purely informational and do not constitute any endorsement of the linked site’s operator or content.</p>
                <p>Therefore, we strongly advise you to review these websites’ Privacy Policy and Terms and Conditions. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
              </div>
              <div className='info'>
                <h3>News Disclaimer</h3>
                <p>Our writers’ and journalists’ opinions are solely their own and do not reflect the opinion of 4E Hub. None of the information you read on 4E Hub should be taken as investment advice, nor does 4E Hub endorse any project that may be mentioned or linked to within our news content. Buying and trading cryptocurrencies should be considered, by you, to be a high-risk activity. We strongly encourage you to do your own due diligence and to seek the advice of a registered professional investment adviser before taking any action related to our news content. Finally, 4E Hub takes no responsibility should you lose money trading cryptocurrencies or investing in initial coin offerings.</p>
              </div>
              <div className='info'> 
                <h3>Cookies and User Data</h3>
                <p>Cookies are files with a small amount of data that is commonly used as an anonymous unique identifier. These are sent to your browser from the website you visit and stored on your computer’s hard drive.</p>
                <p>Our website uses these “cookies” to ensure certain features function properly. This data is not mined, shared, or collected commercially. You are free to delete these cookies from within your web browser.</p>
                <p>You agree to these cookies by visiting our website and using its features.</p>
              </div>
              <div className='info'>
                <h3>Changes to This Agreement</h3>
                <p>4E Hub may update these Terms and Conditions from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Terms and Conditions on this page. These changes are effective immediately after they are posted.</p>
              </div>
              <div className='info'>
                    <h3>Contact Us</h3>
                    <p>If you have any questions or suggestions about our Terms and Conditions, do not hesitate to <a href='#contact'>contact us</a></p>
                </div>
          </div>
        </div>
      </section>
    </>
  )
}