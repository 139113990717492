import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { bsc } from "wagmi/chains";
import { Route, Routes } from "react-router-dom";
import { Footer } from "./app/footer";
import { Header } from "./app/header";
import { NewLetterSection } from "./app/NewLetterSection";
import { PrivacyPolicy } from "./components/privacy-policy/PrivacyPolicy";
import { TermsConditions } from "./components/terms-conditions/TermsConditions";
import HomePage from './app/HomePage';


const chains = [bsc];
const projectId = "f029677387f3e1590dcb9afe7d6772b0";

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

export default function App() {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
          </Routes>
          <NewLetterSection />
          <Footer />
        </div>
      </WagmiConfig>

      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
}
