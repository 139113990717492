import React from 'react'
import { useLocation } from 'react-router-dom'

export const HeaderTop = () => {
  const pathName = useLocation();
  return (
    <div className={`${pathName.pathname != "/" ? "white-header-top" : ""} header-top`}>
      <h3 className='text-white'>
        Join our Discord for the latest news and retroactive airdrops.
      </h3>
      <a
        type="button"
        className="btn blue_btn aos-init aos-animate"
        href="https://discord.com/invite/V9vVdKybTr"
        target="_blank"
        rel="noreferrer"
      >
        <span>
          Join Discord
        </span>
      </a>
    </div>
  )
}