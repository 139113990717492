/* global gapi */

import React from 'react'
import { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../styles/css/news-letter.css"

export const NewLetterSection = () => {
    const [email, setEmail] = useState('');
    const [isValidate, setIsValidate] = useState(true);

    const validateEmail = (e) => {
        setEmail(e.target.value);
        if (email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
            setIsValidate(true)
        } else {
            setIsValidate(false)
        }
    }

    const currentDate = new Date().toJSON().slice(0, 10);
    function exportEmailHandle(e) {
        e.preventDefault();
      const formEle = document.querySelector("form");
      const formDatab = new FormData(formEle);
      fetch(
        "https://script.google.com/macros/s/AKfycbyKycA0YhdFl3PFtgN1NrYg_cq8632uyNJDScmuSrCpx3OPBTJr5pniJ-zVwtvc4IqUr1/exec",
        {
          method: "POST",
          body: formDatab
        }
      )
        .then((res) => toast.success("You successfully sent email !!!"))
        .then((data) => {
        })
        .catch((error) => {
          toast.error("You don't successfully sent email !!!")
        });
    }
    return (
        <section className="newsletter_section">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                theme="colored"
            />
            <div className="container">
                <div className="newsletter_box">
                    <div className="section_title" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                        <h1>Subscribe newsletter</h1>
                        <p>Be the first to recieve all latest post in your inbox</p>
                    </div>
                    <form action="" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100" onSubmit={exportEmailHandle}>
                        <div className="form-group">
                            <input type="email" name="Email" className="form-control" placeholder="Enter your email" onChange={validateEmail} value={email}/>
                            <input name="Date" hidden defaultValue={currentDate} />
                            <p className={`${!isValidate ? 'error-alert_show' : 'error-alert'} `}>Email is not valid</p>
                        </div>
                        <div className="form-group">
                            <button className="btn">SUBMIT</button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}