import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import logo4e from "../../assets/images/logo-4e.png";
import logo4eWhite from "../../assets/images/white_logo_header.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAccount, useDisconnect } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";

export const Navigation = () => {
  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const currentPath = useLocation();
  const [openMobileNav, setOpenMobileNav] = useState(false);

  const onConnect = async () => {
    try {
      if (isConnected) {
        disconnect();
      } else {
        await open();
      }
    } catch (e) {
      console.log("EE:", e);
      toast.error(e.toString());
    }
  };

  const showNavi = () => {
    setOpenMobileNav((prev) => !prev);
  };

  window.addEventListener("scroll", () => {
    if (window.pageYOffset >= 250) {
      document.querySelector(".header").classList.add("fix_style");
    } else {
      document.querySelector(".header").classList.remove("fix_style");
    }
    if (window.pageYOffset >= 260) {
      document.querySelector(".header").classList.add("fixed");
    } else {
      document.querySelector(".header").classList.remove("fixed");
    }
  });

  useEffect(() => {
    setOpenMobileNav(false);
  }, [currentPath.pathname]);
  const [index, setIndex] = useState(0);
  const [section, setSection] = useState("");
  const scrollToSectionHandle = (section) => {
    setSection(section);
    setIndex(document.getElementById(section).offsetTop);
  };
  useEffect(() => {
    switch (section) {
      case "features":
        window.scrollTo(0, index - 50);
        break;
      case "how_it_work":
        window.scrollTo(0, index - 40);
        break;
      case "media":
        window.scrollTo(0, index - 95);
        break;
      case "getstarted":
        window.scrollTo(0, index - 150);
        break;
      default:
        window.scrollTo(0, index - 50);
        break;
    }
  }, [index]);

  return (
    <header className={`header ${currentPath.pathname !== "/" ? 'white_header' : ''}`}>
      <div className="container">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          theme="colored"
        />
        <nav className="navbar navbar-expand-lg">
          <Link
            className="navbar-brand"
            to="/"
            onClick={() => {
              window.scrollTo(0, 0);
            }}>
            <img
              src={currentPath.pathname !== "/" ? logo4eWhite : logo4e}
              alt="logo_4E_HUB"
            />
          </Link>
          <button
            className={`navbar-toggler ${openMobileNav ? "collapsed" : ""}`}
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={showNavi}
          >
            <span className="navbar-toggler-icon">
              {/*<i className="icofont-navigation-menu ico_menu"></i>*/}
              <div className={`toggle-wrap ${openMobileNav ? "active" : ""}`}>
                <span className="toggle-bar"></span>
              </div>
            </span>
          </button>
          <div
            className={`navbar-collapse ${openMobileNav ? "show" : "collapse"}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <span
                  className="nav-link"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  Home
                </span>
              </li>
              <li className="nav-item">
                <span
                  className="nav-link"
                  onClick={() => scrollToSectionHandle("features")}
                >
                  Features
                </span>
              </li>
              <li className="nav-item">
                <span
                  className="nav-link"
                  onClick={() => scrollToSectionHandle("how_it_work")}
                >
                  How it works
                </span>
              </li>
              <li className="nav-item">
                <span
                  className="nav-link"
                  onClick={() => scrollToSectionHandle("media")}
                >
                  Media
                </span>
              </li>
              <li className="nav-item">
                <span
                  className="nav-link"
                  onClick={() => scrollToSectionHandle("testimonial")}
                >
                  Customers
                </span>
              </li>
              <li className="nav-item">
                <span 
                  className="nav-link"
                  onClick={() => scrollToSectionHandle("getstarted")}
                >
                  Download
                </span>
              </li>

              <li className="nav-item">
                <span 
                  className="nav-link"
                  onClick={() => window.scrollTo(0, document.body.scrollHeight)}
                >
                  Contact
                </span>
              </li>
              <li className="nav-item">
                {address ? (
                  <a className="nav-link dark_btn" onClick={() => disconnect()}>
                    Disconnect
                  </a>
                ) : (
                  <a className="nav-link dark_btn" onClick={onConnect}>
                    Connect wallet
                  </a>
                )}
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};
