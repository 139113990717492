import React from 'react'
import OwlCarousel from 'react-owl-carousel2';
import homeScreen from "../../assets/images/home-screen.png"
import newsScreen from "../../assets/images/news-screen.png"
import achivementScreen from "../../assets/images/achivement-screen.png"
import hotJobScreen from "../../assets/images/hotjob-screen.png"
import loginScreen from "../../assets/images/login-screen.png"
import "../../styles/css/interface.css"

export const InterfaceSection = () => {
    const options = {
        loop: true,
        margin: 10,
        nav: false,
        dots: true,
        autoplay: true,
        smartSpeed: 2500,
        center: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 5
            }
        }
    }

    return (
        <section className="row_am interface_section">
            <div className="container-fluid">
                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                    <h1>Beautiful <span>interface</span></h1>
                    <p>
                        For users, the 4E HUB interface encourages ease of use and simplicity. <br /> The 4E HUB team thinks deeply about client usage patterns and aesthetics in addition to usability.
                    </p>
                </div>
                <div className="screen_slider" >
                    <OwlCarousel id="screen_slider" className="owl-carousel owl-theme" options={options}>
                        <div className="item">
                            <div className="screen_frame_img">
                                <img src={newsScreen} alt="news_screen" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="screen_frame_img">
                                <img src={hotJobScreen} alt="hot_job_screen" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="screen_frame_img">
                                <img src={achivementScreen} alt="achivement_screen" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="screen_frame_img">
                                <img src={homeScreen} alt="home_screen" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="screen_frame_img login_frame_screen">
                                <img src={loginScreen} alt="login_screen"/>
                            </div>
                        </div>
                        <div className="item">
                            <div className="screen_frame_img">
                                <img src={homeScreen} alt="home_screen" />
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
        </section>
    )
}
