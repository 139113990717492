import React, { useEffect, useState } from "react";
import animLine from "../../assets/images/anim_line.png";
import messageIcon from "../../assets/images/message_icon.png";
import shieldIcon from "../../assets/images/shield_icon.png";
import screen from "../../assets/images/NFT_4E.png";
import coinImg from "../../assets/images/4E_HUB_NFT.png";
import "../../styles/css/banner-section.css";
import ReadMore from "../read-more/ReadMore";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useContractWrite, useDisconnect } from "wagmi";
import { toast } from "react-toastify";
import abi from "../../contracts/4enft.json";
import { parseEther } from "ethers/lib/utils.js";
import MintModalStyleWrapper from "./MintNow.style";
import Lottie from "lottie-react";
import congrasAnimation from "./congras.json";

export const BannerSection = () => {
  const [tx, setTx] = useState();
  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const { isLoading, writeAsync } = useContractWrite({
    abi,
    args: [address],
    functionName: "mint",
    address: "0xc2D08761fFC5fe3566767C4547B8e4cA63e7b293",
  });

  const onConnect = async () => {
    try {
      if (isConnected) {
        disconnect();
      } else {
        await open();
      }
    } catch (e) {
      console.log("EE:", e);
      toast.error(e.toString());
    }
  };

  const detailsCoin = [
    {
      id: 0,
      fee: "0%",
      type: "ERC-721",
      blockchain: "BNB Chain",
      name: "Souvenir 4E HUB",
      price: 0,
      total: 10000,
    },
  ];

  const descriptionContent = [
    {
      id: 0,
      content:
        "To celebrate our first journey, 4E HUB releases SOUVENIR 4E HUB, as a thank you to our community members who support us.<br/> NFTs can also be one of the criteria we use to determine the winners of future big events.",
    },
  ];

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowSize(window.innerWidth);
    });
  }, [window.innerWidth]);

  const handleMint = async () => {
    const rs = await writeAsync({
      value: parseEther("0.003"),
    });
    setTx(rs?.hash);
    toast.success("Congratulations on your minted NFT success!");
  };

  return (
    <section className="banner_section" id="banner">
      <div className="container">
        <div className="anim_line">
          <span>
            <img src={animLine} alt="anim_line" />
          </span>
          <span>
            <img src={animLine} alt="anim_line" />
          </span>
          <span>
            <img src={animLine} alt="anim_line" />
          </span>
          <span>
            <img src={animLine} alt="anim_line" />
          </span>
          <span>
            <img src={animLine} alt="anim_line" />
          </span>
          <span>
            <img src={animLine} alt="anim_line" />
          </span>
          <span>
            <img src={animLine} alt="anim_line" />
          </span>
        </div>
        <div className="row">
          <div
            className="col-lg-6 col-md-12"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <div className="banner_coin">
              <div className="banner_coin_header">
                <h1 className="header_text">PUBLIC MINT ®</h1>
                <h1 className="name-coin">{detailsCoin[0].name}</h1>
                <p>By: 4E HUB</p>
              </div>
              <div className="col-left">
                <div className="w-50 d-flex flex-column">
                  <img src={coinImg} alt="coin_image" className="coin_image" />
                  <div className="coin_value">
                    <p className="coin_value_name">{detailsCoin[0].name}</p>
                    <p className="coin_value_price">
                      Price: {detailsCoin[0].price}$
                    </p>
                    <p className="coin_value_total">
                      Total: {detailsCoin[0].total}
                    </p>
                  </div>
                </div>
                <div className="w-50">
                  <div className="coin_details">
                    <h3>DETAILS</h3>
                    <div className="d-flex justify-content-between coin_details_content">
                      <div className="label_details">
                        <p>CREATOR FEE</p>
                        <p>TYPE</p>
                        <p>BLOCKCHAIN</p>
                      </div>
                      <div className="details_value">
                        <p>{detailsCoin[0].fee}</p>
                        <p>{detailsCoin[0].type}</p>
                        <p>{detailsCoin[0].blockchain}</p>
                      </div>
                    </div>
                  </div>
                  <div className="coin_description">
                    <h3>DESCRIPTION</h3>
                    <div className="description_content">
                      <p
                        className={`${
                          windowSize <= 767 ? "d-none" : "d-block"
                        }`}
                        dangerouslySetInnerHTML={{
                          __html: descriptionContent[0].content,
                        }}
                      ></p>
                      <ReadMore
                        text={descriptionContent[0].content}
                        lineHeight={1.4}
                        showLessButton={true}
                        className={`${windowSize <= 767 ? "d-bloc" : "d-none"}`}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* {<button className="btn-mint">COMING SOON</button>} */}
              {address ? (
                !isLoading ? (
                  <button onClick={handleMint} className="btn-mint">
                    Mint Souvenir Now
                  </button>
                ) : (
                  <button className="btn-mint">Minting....</button>
                )
              ) : (
                <button onClick={onConnect} className="btn-mint">
                  Connect Wallet
                </button>
              )}
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12"
            data-aos="fade-in"
            data-aos-duration="1500"
          >
            <div className="banner_slider">
              <div className="left_icon">
                <img src={messageIcon} alt="message_icon" />
              </div>
              <div className="right_icon">
                <img src={shieldIcon} alt="shield_icon" />
              </div>
              <div className="item">
                <div className="slider_img">
                  <img src={screen} alt="NFT_4E" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {tx && (
        <MintModalStyleWrapper className="modal_overlay">
          <div
            style={{
              position: "absolute",
            }}
          >
            <Lottie animationData={congrasAnimation} />
          </div>
          <div className="mint_modal_box">
            <div
              style={{
                position: "absolute",
              }}
            >
              <Lottie animationData={congrasAnimation} />
            </div>
            <div className="mint_modal_content">
              <div className="modal_header">
                <h2>Minted NFT</h2>
                <button onClick={() => setTx(null)}>
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line x1={18} y1={6} x2={6} y2={18} />
                    <line x1={6} y1={6} x2={18} y2={18} />
                  </svg>
                </button>
              </div>
              <div className="modal_body text-center">
                <div className="mint_img">
                  <img src={"hub.gif"} style={{ width: "100%" }} alt="hub" />
                </div>
                <div className="mint_count_list">
                  <ul>
                    <li>
                      <h5>Transaction</h5>
                      <h5>
                        <a
                          target="_blank"
                          style={{ color: "#FFF" }}
                          href={`https://bscscan.com/tx/${tx.transactionHash}`}
                          rel="noreferrer"
                        >
                          View
                        </a>
                      </h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </MintModalStyleWrapper>
      )}
    </section>
  );
};
