import React from 'react'
import { useState } from 'react'
import "../../styles/css/faq.css"

export const FaqSection = () => {
    const [openIndex, setOpenIndex] = useState(0);

    const handleCardClick = (index) => {
        if (openIndex === index) {
            setOpenIndex(-1);
        } else {
            setOpenIndex(index);
        }
    };
    return (
        <section className="row_am faq_section" id='faq'>
            <div className="container">
                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                    <h1><span>FAQ</span> - Frequently Asked Questions</h1>
                    <p>Answering problems in the process of using 4E HUB App.</p>
                </div>
                <div className="faq_panel">
                    <div className="accordion" id="accordionExample">
                        <div className="card" data-aos="fade-up" data-aos-duration="1500">
                            <div className="card-header" id="headingOne">
                                <h1 className="mb-0">
                                    <button type="button" className={`btn btn-link ${openIndex === 0 ? 'active' : 'collapsed'}`} data-toggle="collapse" data-target="#collapseOne" onClick={() => handleCardClick(0)}>
                                        <i className={`icon_faq ${openIndex === 0 ? 'icofont-minus' : 'icofont-plus'}`}></i>Why didn't I receive the verification code ?</button>
                                </h1>
                            </div>
                            <div id="collapseOne" className={`collapse ${openIndex === 0 ? 'show' : ''}`} aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div className="card-body">
                                    <p>Case 1: due to user entering wrong phone number or email.<br />
                                        Case 2: if you have tried to enter the phone number and email used to register and still do not receive the verification code, contact Hotline…. to report the problem so that 4E HUB App can promptly fix it. Or you can send feedback to: xxxxgmail.com for support.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card" data-aos="fade-up" data-aos-duration="1500">
                            <div className="card-header" id="headingTwo">
                                <h1 className="mb-0">
                                    <button type="button" className={`btn btn-link ${openIndex === 1 ? 'active' : 'collapsed'}`} data-toggle="collapse"
                                        data-target="#collapseTwo" onClick={() => handleCardClick(1)}><i className={`icon_faq ${openIndex === 1 ? 'icofont-minus' : 'icofont-plus'}`}></i>How to register by email?</button>
                                </h1>
                            </div>
                            <div id="collapseTwo" className={`collapse ${openIndex === 1 ? 'show' : ''}`} aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div className="card-body">
                                    <p>Step 1: Fill in your email. <br />
                                        Step 2: Enter the password
                                        Capital letters, digits, and other characters should be used in the password to boost account security. Note: do not share this password with anyone else to avoid losing your account.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}