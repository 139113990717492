import React, {useRef} from 'react'
import animLine from "../../assets/images/anim_line.png"
import downloadScreen1 from "../../assets/images/about-app.png"
import downloadScreen2 from "../../assets/images/intro_screen.png"
import appstoreBlue from "../../assets/images/appstore_black.png"
import googleplayBlue from "../../assets/images/googleplay_black.png"
import qrCode from "../../assets/images/QR-code.png"
import "../../styles/css/download-app.css"

export const DownloadAppSection = () => {
    const refDownload = useRef(null)

    return (
        <section className="row_am free_app_section" id="getstarted" ref={refDownload}>
            <div className="container">
                <div className="free_app_inner" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                    <div className="anim_line dark_bg">
                        <span><img src={animLine} alt="anim_line" /></span>
                        <span><img src={animLine} alt="anim_line" /></span>
                        <span><img src={animLine} alt="anim_line" /></span>
                        <span><img src={animLine} alt="anim_line" /></span>
                        <span><img src={animLine} alt="anim_line" /></span>
                        <span><img src={animLine} alt="anim_line" /></span>
                        <span><img src={animLine} alt="anim_line" /></span>
                        <span><img src={animLine} alt="anim_line" /></span>
                        <span><img src={animLine} alt="anim_line" /></span>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="free_text">
                                <div className="section_title">
                                    <h1>Let's download for 4E HUB App free from the Apple App Store and Google Play Store now</h1>
                                    <p>Try out the 4E HUB App to learn more about the world of cryptocurrencies right away!</p>
                                </div>
                                <div className='download-app'>
                                    <p className='label-download' style={{ display: 'none' }}>Download our app!</p>
                                    <ul className="app_btn">
                                        <div className='row-left'>
                                            <img src={qrCode} alt="qr-code" className='qr-img' />
                                        </div>
                                        <li>
                                            <a href="https://play.google.com/store/apps/details?id=com.hdempires.hd4ehub" target='_blank'>
                                                <img src={googleplayBlue} alt="google_play_badge"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://apps.apple.com/app/4e-hub/id6448568895" target='_blank'>
                                                <img src={appstoreBlue} className="appstore-badge" alt="app_store_badge"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="free_img">
                                <img src={downloadScreen1} alt="download_screen_1" />
                                <img  className="mobile_mockup"  src={downloadScreen2} alt="download_screen_2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}