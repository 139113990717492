import React, { useEffect, useState } from 'react'
import downLoadAppScreen from "../../assets/images/download-app.png"
import createAccountScreen from "../../assets/images/create-account.png"
import enjoyAppScreen from "../../assets/images/enjoy-app.png"
import thumbnail from "../../assets/images/intro_4e.jpeg"
import playIcon from "../../assets/images/play_icon.png"
import step1 from "../../assets/images/step-1.png"
import step2 from "../../assets/images/step-2.png"
import step3 from "../../assets/images/step-3.png"
import "../../styles/css/how-work.css"
import ReactPlayer from 'react-player'

export const HowItWorkSection = () => {
  const [videoIsPlay, setVideoIsPlay] = useState(false);
  const [played, setPlayed] = useState(0)

  const playVideoHandler = () => {
    setVideoIsPlay(true);
  }

  const checkpageYOffset = (y) => {
    window.addEventListener("scroll", () => {
      if(window.pageYOffset < y){
        setVideoIsPlay(false)

      }
    })
  }

  useEffect(() => {
    window.addEventListener("resize", () => {
      if(window.innerWidth > 900){
        checkpageYOffset(2700)
      }else{
        checkpageYOffset(6800)
      }
    })

  }, [window.innerWidth])
  

  return (
    <section className="row_am how_it_works" id="how_it_work">
      <div className="container">
        <div className="how_it_inner">
          <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
            <h1><span>How it works</span> - 3 easy steps</h1>
            <p>With a few clicks, 4E HUB's all-in-one application will introduce you to a new world of prospective investment opportunities.</p>
          </div>
          <div className="step_block">
            <ul>
              <li>
                <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                  <h4>Download app</h4>
                  <div className="app_icon">
                    <a href="https://play.google.com/store/apps/details?id=com.hdempires.hd4ehub" target='_blank' rel="noreferrer"><i className="icofont-brand-android-robot"></i></a>
                    <a href="https://apps.apple.com/app/4e-hub/id6448568895" target='_blank' rel="noreferrer"><i className="icofont-brand-apple"></i></a>
                  </div>
                  <p>Download 4E HUB App either for IOS or Android</p>
                </div>
                <div className="step_number">
                <img src={step1} alt="step-1"/>
                </div>
                <div className="step_img" data-aos="fade-left" data-aos-duration="1500">
                  <img src={downLoadAppScreen} alt="download_app_screen" />
                </div>
              </li>
              <li>
                <div className="step_text" data-aos="fade-left" data-aos-duration="1500">
                  <h4>Create account</h4>
                  <p>Sign up free for App account. One account for all devices.</p>
                </div>
                <div className="step_number">
                  <img src={step2} alt="step-2"/>
                </div>
                <div className="step_img" data-aos="fade-right" data-aos-duration="1500">
                  <img src={createAccountScreen} alt="create_account_screen" />
                </div>
              </li>
              <li>
                <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                  <h4>Enjoy the app</h4>
                  <span>Have any questions check our <a href="#faq">FAQs</a></span>
                  <p>Get most amazing app experience, Explore and share the app</p>
                </div>
                <div className="step_number">
                <img src={step3} alt="step-3"/>
                </div>
                <div className="step_img" data-aos="fade-left" data-aos-duration="1500">
                  <img src={enjoyAppScreen} alt="enjoy_app_screen" />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="yt_video" data-aos="fade-in" data-aos-duration="1500" id='media'>
          <div className={`${!videoIsPlay ? 'd-block' : 'd-none'} thumbnil`}>
            <img src={thumbnail} alt="thumbnail" className='thumbnail-img'/>
            <div className="play-button" onClick={playVideoHandler}>
              <span className="play_btn">
                <img src={playIcon} alt="play_icon" />
                <div className="waves-block">
                  <div className="waves wave-1"></div>
                  <div className="waves wave-2"></div>
                  <div className="waves wave-3"></div>
                </div>
              </span>
              Let's see the introduction about us
              <span>Watch video</span>
            </div>

          </div>

          <div className={`${videoIsPlay ? 'd-block' : 'd-none'} iframe-youtube`} >
            <ReactPlayer url='https://youtu.be/cLhqjU3N9Dk' width="100%" height="100%" playing={videoIsPlay} controls={true} loaded={played}/>
          </div>
        </div>
      </div>
    </section>
  )
}