import React from 'react'
import OwlCarousel from 'react-owl-carousel2';
import logoHD from "../../assets/images/logo-hd.png"
import logoCorgi from "../../assets/images/logo-corgi.png"
import logoZens from "../../assets/images/logo-zens.svg"
import logoBiscope from "../../assets/images/logo-biscope.png"
import "../../styles/css/trusted.css"

export const TrustedSection = () => {
    const options = {
        loop: true,
        margin: 10,
        nav: false,
        autoplay: true,
        smartSpeed: 1500,
        dots: true,
        responsive: {
            0: {
                items: 2
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    }

    return (
        <section className="row_am trusted_section">
            <div className="container">
                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                    <h1><span>Partners</span>, investors, sponsors</h1>
                    <p>In the future, as the 4E HUB project expands and becomes more renowned, we always want to be a worldwide application that connects and collaborates with all units in a wide range of industries throughout the world.</p>
                </div>
                <div className="company_logos" >
                    <OwlCarousel id="company_slider" className="owl-carousel owl-theme" options={options}>
                        <div className="item">
                            <a href='https://www.facebook.com/HDEmpires' className="logo logo-hd" target='_blank'>
                                <img src={logoHD} alt="logo-HD" />
                            </a>
                        </div>
                        <div className="item">
                            <a href='https://corgidoge.com/' className="logo logo-corgi" target='_blank'>
                                <img src={logoCorgi} alt="logo-Corgi" />
                            </a>
                        </div>
                        <div className="item">
                            <a href='https://zen-s.com/' className="logo logo-zens" target='_blank'>
                                <img src={logoZens} alt="logo-Zens" />
                            </a>
                        </div>
                        <div className="item">
                            <a href='https://bitscope.global/' className="logo logo-biscope" target='_blank'>
                                <img src={logoBiscope} alt="logo-Biscope" />
                            </a>
                        </div>
                    </OwlCarousel>
                </div>

            </div>

        </section>
    )
}
