import React from 'react'
import { useEffect } from 'react';
import { AboutAppSection } from '../components/about-app/AboutAppSection';
import { BannerSection } from '../components/home-page/BannerSection';
import { DownloadAppSection } from '../components/download-app/DownloadAppSection';
import { FaqSection } from '../components/home-page/FaqSection';
import { FeaturesSection } from '../components/home-page/FeaturesSection';
import { HowItWorkSection } from '../components/home-page/HowItWorkSection';
import { InterfaceSection } from '../components/home-page/InterfaceSection';
import { OutStanding } from '../components/home-page/OutStanding';
import { TestimonialSection } from '../components/home-page/TestimonialSection';
import { TrustedSection } from '../components/trusted/TrustedSection';
import { TypeUsersSection } from '../components/home-page/TypeUsersSection';
import { Header } from './header';
import { NewLetterSection } from './NewLetterSection';
import { Footer } from './footer';

export default function HomePage() {

  useEffect(() => {
    document.title = '4E HUB';
  }, []);

  window.addEventListener('scroll', function () {
    var element = document.querySelector('.free_text');
    if (!element) return
    var position = element.getBoundingClientRect();

    if (position.top <= window.innerHeight && position.bottom >= 800) {
        document.querySelector('.blue_backdrop').style.opacity = 1;
    } else {
        document.querySelector('.blue_backdrop').style.opacity = 0;
    }
});

  return (
    <div>
      <BannerSection />
      <TrustedSection />
      <FeaturesSection />
      <AboutAppSection />
      <OutStanding />
      <HowItWorkSection />
      <TestimonialSection />
      <TypeUsersSection />
      <FaqSection />
      <InterfaceSection />
      <DownloadAppSection />
      <div className="blue_backdrop"></div>
    </div>
  )
}